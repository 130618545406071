import { useState, useEffect, useRef, useLayoutEffect, Dispatch, SetStateAction } from "react";
import { modalStore } from "../../../state";
import { authDetailsType } from "../cardtypes";
import { allowOnlyNumeric, formatPhoneNumber } from "../../../utils/formats";

interface CardEnrollType {
  authorizeCharge: (e: string) => any;
  setAuthDetails: Dispatch<SetStateAction<authDetailsType>>;
  phone: string;
}

export default function CardEnroll({
  authorizeCharge,
  setAuthDetails,
  phone,
}: CardEnrollType) {
  const {
    cardEnrollMessage,
    testCard,
    update,
    paymentLink
  } = modalStore();

  useLayoutEffect(() => {
    update({ page: "Card CARD_ENROLL", buttonAction: "changePayment" });
  }, []);

  const [cardEnrollState, setCardEnrollState] = useState({
    disabled: false,
  });
  const submitButtonRef = useRef<null | HTMLButtonElement>(null);

  const submitCardEnrollForm = useRef((e) => {
    // Enter key triggers the authorization
    if (e.keyCode === 13) {
      const submitButton = submitButtonRef.current as HTMLButtonElement;
      if (!submitButton?.classList?.contains("disabled")) {
        (submitButton).click();
      }
      return;
    }
  });

  const authorizePhone = () => {
    if (!testCard && ((phone && phone.length < 11) || cardEnrollState.disabled))
      return;
    authorizeCharge("phone");
    setCardEnrollState({ disabled: true });
  };

  useEffect(() => {
    const submitPhone = submitCardEnrollForm.current;
    window.addEventListener("keydown", submitPhone);

    document.getElementById("card-phone")?.focus();
    return () => window.removeEventListener("keydown", submitPhone);
  }, []);

  return (
    <div className="kpy-col__pay-form">
      <p className="details-head" id="card-phone-message">
        {cardEnrollMessage || 'Enter the mobile number linked to the card to recieve an OTP.'}
      </p>
      <section className="channel_container">
        <div className="kpy-col-input-field">
          <label htmlFor="card-phone" className="kpy-col-label">
            Mobile number
          </label>
          <div className="phone-input-field">
            <p>+234</p>
            <input
              aria-label="card-phone"
              aria-describedby="card-phone-message"
              className={`kpy-col-input --card-details phone_input ${phone && phone.length < 11 && 'error-input'}`}
              name="card-phone"
              id="card-phone"
              type="text"
              pattern="^[0-9]*$"
              maxLength={12}
              inputMode="numeric"
              readOnly={Boolean(testCard)}
              value={testCard ? formatPhoneNumber(testCard.phone).slice(1) : formatPhoneNumber(phone).slice(1)}
              onChange={event => {
                setAuthDetails(prevAuthDetails => ({
                  ...prevAuthDetails,
                  phone: `0${allowOnlyNumeric(event.target.value.replace(/\s+/g, ''))}`
                }));
                setCardEnrollState({ disabled: false });
              }}
              placeholder="802 000 0000"
            />
          </div>
          {phone && phone.length < 11 && <p className="kpy-input-field-error">Incomplete mobile number</p>}
        </div>
      </section>
      <button
        className={`kpy-col-btn phone-form-btn ${
          !testCard && ((phone && phone.length < 11) || cardEnrollState.disabled) ? 'disabled' : ''
        }`}
        ref={submitButtonRef}
        aria-disabled={!testCard && ((phone && phone.length < 11) || cardEnrollState.disabled)}
        style={{
          background: paymentLink?.checkout_customization?.primary_color,
          color: paymentLink?.checkout_customization?.secondary_color
        }}
        onClick={authorizePhone}
      >
        <span>Send OTP</span>
      </button>
    </div>
  );
}
