import { Mixpanel, capitalize, formatAmount, hasChannelSwitch, onVerifyPage, smartTrack } from '../utils';
import storage from '../utils/storage';
import { failedCardStatus, failed, validMessageTypes } from './errorReducer';

const success = (amount, state) => ({
  ...state,
  amount,
  page: 'Payment successful',
  loader: false,
  modalState: 'success',
  outgoingStatus: 'success',
  feedback: true,
  buttonAction: 'none',
  feedbackImage: 'https://korablobstorage.blob.core.windows.net/modal-bucket/modal-icon-success.svg'
});

export default function successReducer(type, response, state) {
  switch (type) {
    case 'GET_TRANSACTION': {
      const {
        currency,
        payment_channels,
        amount,
        fees,
        reference,
        public_key,
        merchant_bears_cost,
        customer,
        merchant,
        redirect_url,
        status,
        supportEmail,
        unique_reference,
        default_payment_channel,
        conversion
      } = response;
      Mixpanel.identify(`${unique_reference}__${reference}`);
      Mixpanel.track('Checkout Initiated');
      Mixpanel.people.set({
        $name: customer?.name || '',
        $email: customer?.email || ''
      });

      Mixpanel.register({
        'Payment Reference': reference,
        'Merchant Name': merchant?.name || '',
        Amount: `${currency} ${formatAmount(amount)}`
      });

      if (status === 'complete') {
        return {
          ...state,
          loader: false,
          feedback: true,
          errorMessage: 'This transaction has already been completed. Please try a new transaction',
          feedbackImage: 'https://korablobstorage.blob.core.windows.net/modal-bucket/icon-exclamation.svg',
          modalState: 'issue detected',
          issueStatus: status,
          buttonAction: 'close',
          payment_reference: reference,
          redirect_url
        };
      }

      if (status === 'abandoned') {
        return {
          ...state,
          loader: false,
          feedback: true,
          errorMessage: 'This transaction was abandoned. Please try a new transaction',
          feedbackImage: 'https://korablobstorage.blob.core.windows.net/modal-bucket/icon-exclamation.svg',
          modalState: 'issue detected',
          issueStatus: status,
          buttonAction: 'close',
          payment_reference: reference,
          redirect_url
        };
      }

      return {
        ...state,
        amount,
        fees: merchant_bears_cost ? null : fees,
        payment_reference: reference,
        unique_reference,
        currency,
        public_key,
        merchant: {
          ...merchant,
          supportEmail
        },
        customer: { name: customer.name, email: customer.email },
        channels: payment_channels,
        isOtpIncorrect: false,
        stage: 'init',
        card: {
          stage: 'init',
          token_id: '',
          redirectUrl: '',
          cardType: ''
        },
        loader: false,
        feedback: false,
        buttonAction: 'close',
        modalState: 'loaded',
        testCard: null,
        redirect_url,
        hideHeading: true,
        page: 'Payment method',
        default_channel: default_payment_channel,
        allowMultipleChannels: hasChannelSwitch(payment_channels),
        conversion
      };
    }
    case 'CONFIRM_PAYMENT_LINK': {
      const { merchant, public_key } = response;

      return {
        ...state,
        loader: false,
        modalState: 'paymentLink',
        feedback: false,
        paymentLink: response,
        merchant,
        public_key,
        page: 'Payment link'
      };
    }
    case 'INIT_CHARGE_PAYMENTLINK': {
      const {
        currency,
        payment_channels,
        amount,
        fees,
        merchant_bears_cost,
        customer,
        payment_reference,
        unique_reference,
        supportEmail,
        default_payment_channel,
        payment_channels_details
      } = response;
      const { merchant, public_key } = state.paymentLink;
      Mixpanel.identify(`${unique_reference}__${payment_reference}`);
      Mixpanel.people.set({
        $name: customer?.name || '',
        $email: customer?.email || ''
      });

      Mixpanel.register({
        'Payment Reference': payment_reference,
        'Merchant Name': merchant?.name || '',
        Amount: `${currency} ${formatAmount(amount)}`
      });

      Mixpanel.track('Checkout Initiated');

      return {
        ...state,
        amount,
        fees: merchant_bears_cost ? null : fees,
        payment_reference,
        unique_reference,
        currency,
        public_key,
        merchant: {
          ...merchant,
          supportEmail
        },
        customer: { name: customer.name, email: customer.email },
        channels: payment_channels,
        isOtpIncorrect: false,
        stage: 'init',
        card: {
          stage: 'init',
          token_id: '',
          redirectUrl: '',
          cardType: ''
        },
        mobileMoney: {
          stage: 'mobile_money'
        },
        loader: false,
        feedback: false,
        buttonAction: 'close',
        modalState: 'loaded',
        testCard: null,
        redirect_url: null,
        page: 'Payment method',
        default_channel: default_payment_channel,
        allowMultipleChannels: hasChannelSwitch(payment_channels),
        payment_channels_details
      };
    }
    case 'RESEND_CHARGE_MOBILE_MONEY':
    case 'INIT_CHARGE_MOBILE_MONEY': {
      const { message } = response;
      if (message.includes('insufficient')) {
        const errorMessage = message;
        return {
          ...failed(errorMessage, state)
        };
      }
      if (response?.message.includes('invalid')) {
        return {
          ...state,
          loader: false,
          invalidNumberErrorMessage: response?.message
        };
      }
      return {
        ...state,
        mobileMoney: {
          ...state.mobileMoney,
          stage: response?.auth_model?.includes('OTP') && response?.currency !== 'KES' ? 'mobile_money_otp' : 'mobile_money_check'
        },
        reference: response?.reference,
        loader: false,
        allowMultipleChannels: false,
        mobileMoneySecondLapse: Date.now() + 10000,
        canResendOtp: response?.auth_model?.includes('OTP'),
        canResendStk: response?.metadata?.can_resend_stk
      };
    }
    case 'GET_CONVERSION_RATE': {
      return {
        ...state,
        conversion: response,
        exchangeRateExpired: false,
        conversionRateUpdated: true,
        loader: false
      };
    }
    case 'AUTH_CHARGE_MOBILE_MONEY': {
      if (response?.message.includes('invalid')) {
        return {
          ...state,
          loader: false,
          isOtpIncorrect: true,
          errorMessage: response?.data?.token?.customErrorMessage
        };
      }
      return {
        ...state,
        mobileMoney: {
          ...state.mobileMoney,
          stage: 'mobile_money_check'
        },
        reference: response?.reference,
        loader: false,
        allowMultipleChannels: false,
        mobileMoneySecondLapse: Date.now() + 10000
      };
    }
    case 'RESEND_MOBILE_MONEY_STK': {
      if (response?.message.includes('exceeded')) {
        return {
          ...state,
          resendLimitExceeded: true,
          loader: false
        };
      }
      return {
        ...state,
        mobileMoney: {
          ...state.mobileMoney,
          stage: 'mobile_money_check'
        },
        loader: false,
        allowMultipleChannels: false,
        mobileMoneySecondLapse: Date.now() + 10000
      };
    }
    case 'CONFIRM_SANDBOX_MOBILE_MONEY':
    case 'CONFIRM_MOBILE_MONEY': {
      const { status, amount, message } = response;
      if (message?.includes('not correct')) {
        return {
          ...state,
          loader: false,
          isOtpIncorrect: true,
          errorMessage: response?.message
        };
      }
      if (status === 'success')
        return {
          ...success(state.amount),
          successMessage: `Your payment of ${state.currency} ${formatAmount(amount || state.amount)} has been received.`
        };
      if (status === 'failed') return failed(message, state);
      return;
    }
    case 'QUERY_MOBILE_MONEY_EGANOW': {
      const { status, amount, message } = response;
      if (status === 'processing') {
        return {
          ...state,
          mobileMoney: {
            ...state.mobileMoney,
            stage: 'mobile_money_payment_query'
          },
          loader: false,
          buttonAction: 'helpActions',
          hideHeading: true
        };
      }
      if (status === 'success')
        return {
          ...success(state.amount),
          successMessage: `Your payment of ${state.currency} ${formatAmount(amount || state.amount)} has been received.`
        };
      if (status === 'failed') return failed(message, state);
      return;
    }
    case 'INIT_CHARGE_BANKTRANSFER': {
      const { bank_account, reference } = response;
      Mixpanel.register({
        transactionReference: reference
      });
      Mixpanel.track('Account Number Generated', {
        'Generated Account Number': bank_account?.account_number,
        'Generated Bank Name': bank_account?.bank_name,
        'Generated Account Name': bank_account?.account_name
      });
      return {
        ...state,
        reference,
        loader: false,
        stage: 'bank_transfer',
        bankDetails: bank_account,
        buttonAction: 'helpActions',
        hideHeading: false,
        page: 'Account generated'
      };
    }
    case 'CONFIRM_PAYWITHBANK': {
      const { status } = response;
      if (status === 'success') {
        smartTrack(`Payment successful`, state.bankName);
        return {
          ...success(state.amount),
          successMessage: 'Awesome! Your payment was successful.',
          openIframe: false,
          buttonAction: 'none',
          feedback: true,
        };
      }
      if (status === 'processing') {
        return state;
      }
      if (status === 'failed') {
        return {
          ...failed("Sorry, your payment was not completed.", state),
          feedback: true,
          buttonAction: 'none'
        };
      }
      if (status === 'expired') {
        return {
          ...state,
          loader: false,
          feedback: true,
          openIframe: false,
          errorMessage: 'This transaction has expired. Please try a new transaction',
          feedbackImage: 'https://korablobstorage.blob.core.windows.net/modal-bucket/icon-exclamation.svg',
          modalState: 'issue detected',
          issueStatus: status,
          buttonAction: 'changePayment'
        };
      }
      return;
    }
    case 'CONFIRM_BANKTRANSFER': {
      const { status, amount_collected, amount_charged } = response;

      if (status === 'success') {
        switch (true) {
          case amount_collected > amount_charged:
            return {
              ...success(state.amount),
              amount_paid: amount_collected,
              modalState: 'overpaid',
              page: 'Payment overpaid'
            };
          default:
            return {
              ...success(state.amount),
              amount_paid: amount_collected,
              successMessage: 'Awesome! Your payment was successful.'
            };
        }
      }

      if (status === 'rejected')
        return {
          ...success(state.amount),
          amount_paid: amount_collected,
          modalState: 'return',
          isResponseViaApi: true,
          bankDetails: null,
          outgoingStatus: 'rejected',
          page: 'Payment underpaid',
          buttonAction: 'otherOptions',
          feedbackImage: 'https://korablobstorage.blob.core.windows.net/modal-bucket/modal-icon-warning.svg'
        };
      return;
    }
    case 'INIT_CHARGE_BANK_DEBIT': {
      const auth_data = response?.data?.auth_data;
      if (response?.data?.auth_data?.redirect_url) {
        return {
          ...state,
          openIframe: true,
          loader: false,
          redirectUrl: response?.data?.auth_data?.redirect_url,
          reference: response?.data?.reference,
          pay_with_bank: 'bank-pending',
          headingImage: null
        };
      }
      return {
        ...state,
        pay_with_bank: 'bank-authorize',
        otpMessage: response.message,
        loader: false,
        reference: response?.data?.reference,
        bank_slug: response?.data?.bank_account?.bank_name,
        buttonAction: 'close',
        validateAccount: auth_data?.step === 'initiate',
        canResendOtp: auth_data?.step === 'initiate'
      };
    }
    case 'REDIRECT_URL': {
      return {
        ...state,
        openIframe: true,
        loader: false,
        redirectUrl: response?.auth_data?.redirect_url,
        reference: response?.reference,
      };
    }
    case 'AUTHORIZE_BANK_DEBIT': {
      const { error_type, auth_data } = response.data;
      if (error_type)
        return {
          ...state,
          errorMessage: response.message,
          loader: false,
          pay_with_bank: 'bank-authorize',
          isOtpIncorrect: true
        };

      if (auth_data?.step === 'initiate')
        return {
          ...state,
          pay_with_bank: 'bank-authorize',
          otpMessage: response.message,
          hideHeading: false,
          loader: false,
          validateAccount: false
        };

      return {
        ...state,
        pay_with_bank: 'bank-pending',
        hideHeading: true,
        loader: false
      };
    }
    case 'INIT_CHARGE_CARD': {
      const { status, transaction_reference, auth_model, metadata } = response;
      const {
        card: { cardType }
      } = state;

      Mixpanel.register({
        'Transaction Reference': transaction_reference,
        'Card Type': capitalize(cardType)
      });
      Mixpanel.track('Card Payment Initiation Success');

      if (status === 'success')
        return {
          ...success(state.amount),
          successMessage: 'Awesome! Your payment was successful.'
        };

      if (status === 'processing' || status === 'pending') {
        if (auth_model) {
          return {
            ...state,
            loader: false,
            feedback: false,
            buttonAction: 'close',
            metadata,
            reference: transaction_reference,
            card: {
              ...state.card,
              stage: auth_model.toLowerCase()
            }
          };
        }
      }
      if (status === 'failed') return failedCardStatus(response, state);
      break;
    }
    case 'AUTH_VERIFY_CARD_PIN':
    case 'AUTH_VERIFY_CARD_OTP':
    case 'AUTH_VERIFY_CARD_AVS':
    case 'AUTH_VERIFY_CARD_PHONE':
    case 'QUERY_CARD_CHARGE': {
      const { status, transaction_reference, auth_model, response_message, error_type, metadata } = response;
      if (status === 'success') {
        storage.setItem('titan', { email: state.customer.email, id: state.paymentLink?.merchant_id, token: metadata?.browser_card_token });
        return {
          ...success(state.amount),
          successMessage: 'Awesome! Your payment was successful.'
        };
      }

      if (status === 'processing' || status === 'pending') {
        if (auth_model === 'OTP') {
          const metadata = response?.metadata;

          if (response_message.includes('invalid')) {
            return {
              ...state,
              loader: false,
              isOtpIncorrect: true,
              errorMessage: response_message
            };
          }

          return {
            ...state,
            loader: false,
            feedback: false,
            buttonAction: 'close',
            reference: transaction_reference,
            otpMessage: response_message,
            canResendOtp: metadata?.can_resend_otp,
            metadata,
            card: {
              ...state.card,
              stage: auth_model.toLowerCase()
            }
          };
        }

        if (auth_model === 'CARD_ENROLL') {
          if (error_type) {
            return {
              ...failedCardStatus(response, state),
              buttonAction: 'changePayment',
              card: {
                ...state.card,
                stage: auth_model.toLowerCase()
              }
            };
          }

          return {
            ...state,
            loader: false,
            feedback: false,
            buttonAction: 'changePayment',
            reference: transaction_reference,
            cardEnrollMessage: response_message,
            card: {
              ...state.card,
              stage: auth_model.toLowerCase()
            }
          };
        }

        if (error_type) {
          return failedCardStatus(response, state);
        }

        return {
          ...state,
          loader: false,
          feedback: false,
          buttonAction: 'close',
          reference: transaction_reference,
          card: {
            ...state.card,
            stage: auth_model.toLowerCase()
          }
        };
      }

      if (status === 'failed') return failedCardStatus(response, state);
      break;
    }
    case 'VERIFY_CARD_CHARGE': {
      const {
        status,
        amount,
        auth_model,
        channel,
        error_type,
        response_message,
        transaction_reference,
        payment_reference,
        currency,
        redirect_url
      } = response;

      Mixpanel.track('Charge Verification', {
        'Payment Reference': payment_reference,
        Amount: `${currency} ${formatAmount(amount)}`,
        'Transaction Reference': transaction_reference,
        'Transaction Status': capitalize(status)
      });

      if (status === 'success')
        return {
          ...success(amount),
          amount,
          payment_reference,
          currency,
          redirect_url,
          auth_model,
          channel,
          transaction_reference,
          successMessage: 'Awesome! Your payment was successful.'
        };

      if (status === 'processing' && auth_model === '3DS') {
        return {
          ...failed(
            'There seems to be an issue verifying this transaction, please reload this page. If problem persists, please contact support.',
            state
          ),
          feedbackImage: 'https://korablobstorage.blob.core.windows.net/modal-bucket/icon-exclamation.svg',
          modalState: 'Something’s wrong',
          buttonAction: onVerifyPage(channel) ? 'otherOptions' : 'none',
          amount,
          payment_reference,
          currency,
          redirect_url,
          auth_model,
          channel,
          transaction_reference
        };
      }

      if (error_type) {
        if (validMessageTypes.includes(error_type)) {
          return {
            ...failed(response_message, state),
            buttonAction: onVerifyPage(channel) ? 'otherOptions' : 'none',
            amount,
            payment_reference,
            currency,
            redirect_url,
            auth_model,
            channel,
            transaction_reference
          };
        }
      }

      return {
        ...failed('Sorry, your payment was not completed.', state),
        buttonAction: onVerifyPage(channel) ? 'otherOptions' : 'none',
        amount,
        payment_reference,
        currency,
        redirect_url,
        auth_model,
        channel,
        transaction_reference
      };
    }
    case 'QUERY_TRANSACTION':
    case 'AUTH_THREEDS_TEST': {
      return {
        ...state,
        loader: false
      };
    }
    case 'GET_SAVEDCARDS': {
      const { cards } = response;
      return {
        ...state,
        loader: false,
        cards
      };
    }
    case 'DELETE_SAVEDCARD': {
      const { browser_card_token } = response;
      if (!browser_card_token) {
        storage.deleteItem('titan', { email: state.customer.email, id: state.paymentLink?.merchant_id });
        Mixpanel.track('Token Deletion');
        return {
          ...state,
          loader: false,
          card: {
            stage: 'init'
          },
          buttonAction: 'changePayment',
          hideHeading: false,
          browser_card_token
        };
      }
      storage.deleteItem('titan', { email: state.customer.email, id: state.paymentLink?.merchant_id, token: browser_card_token });
      Mixpanel.track('Tokenized card Deleted');
      return {
        ...state,
        loader: false,
        card: {
          stage: 'savedcards'
        },
        buttonAction: 'savedCard',
        browser_card_token
      };
    }
    default:
      return state;
  }
}
