import CryptoJS from 'crypto-js';
import Cookies from 'js-cookie';

const key = `${process.env.VITE_ZEUS_TRIDENT}`;

interface ValueType {
  email: string;
  token?: string;
  id: string;
}
export const encryptContent = (value: ValueType[]) => {
  const stringifyValue = JSON.stringify(value);
  return CryptoJS.AES.encrypt(stringifyValue, key).toString();
};

export const decryptContent = (value: string) => {
  const bytes = CryptoJS.AES.decrypt(value, key);
  const data = bytes.toString(CryptoJS.enc.Utf8);
  return data;
};

const storage = {
  getItem: (name: string): ValueType[] | [] => {
    const item = Cookies.get(name);
    if (!item) return [];
    const decryptedData = decryptContent(item)
    if(decryptedData){
      return JSON.parse(decryptedData);
    }
    return []
  },
  setItem: (name: string, value: ValueType): void | null=> {
    const storedData: any = storage.getItem(name) || [];
    const existingIndex = storedData.findIndex((item: ValueType) => item.email === value.email && item.id === value.id);
    if (existingIndex !== -1) {
      storedData[existingIndex].token = value.token;
    } else {
      storedData.push(value);
    }
    Cookies.set(name, encryptContent(storedData), { expires: 365 });
  },
  deleteItem: (name: string, value: ValueType): void | null=> {
    const storedData: any = storage.getItem(name) || [];
    const existingIndex = storedData.findIndex((item: ValueType) => item.email === value.email && item.id === value.id);
     if (existingIndex !== -1) {
       storedData.splice(existingIndex, 1);
       Cookies.set(name, encryptContent(storedData), { expires: 365 });
     }
  }
};

export default storage;
