import mixpanel from 'mixpanel-browser';
import { environments } from './formats';

mixpanel.init(process.env.VITE_MIXPANEL_ID);
const env_check = process.env.VITE_ENV_TYPE !== environments.TEST && process.env.NODE_ENV !== 'test';

const actions = {
  identify: (id: string) => {
    if (env_check) mixpanel?.identify(id) || console.warn("[IDENTITY]: There has been an error in this transaction's monitoring");
  },
  track: (name: string, props: Record<string, unknown>) => {
    if (env_check) mixpanel?.track(name, props) || console.warn("[TRACK]: There has been an error in this transaction's monitoring");
  },
  register: (props: Record<string, unknown>) => {
    if (env_check) mixpanel?.register(props) || console.warn("[REGISTER]: There has been an error in this transaction's monitoring");
  },
  unregister: (event: Record<string, unknown>) => {
    if (env_check) mixpanel?.unregister(event) || console.warn("[UNREGISTER]: There has been an error in this transaction's monitoring");
  },
  people: {
    set: (props: Record<string, unknown>) => {
      if (env_check) mixpanel?.people?.set(props) || console.warn("[PEOPLE]: There has been an error in this transaction's monitoring");
    }
  },
  reset: () => {
    if (env_check) mixpanel?.reset() || console.warn("[RESET]: There has been an error in this transaction's monitoring");
  }
};

export const Mixpanel = actions;
