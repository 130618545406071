import React, {
  useLayoutEffect,
  useState,
  useCallback,
  useRef,
  useEffect,
} from "react";
import { modalStore } from "../../../state";
import { switchCountryCode } from '../../../utils';
import APIRequest from "../../../services/api-service";
import BankUnavailable from "../../../assets/cant-find-bank.svg";
import ListDropdown from "../../shared/list-dropdown";


const api = new APIRequest();

const BankNotAvailable = () => {
  const { update, public_key, currency } = modalStore();
  const [submitted, setSubmitted] = useState(false);
  const [isFetchingBanks, setIsFetchingBanks] = useState(false);
  const [banks, setBanks] = useState([]);
  const [isError, setError] = useState(false);
  const [selectedBank, setSelectedBank] = useState("");
  const [dropdown, setDropdown] = useState({
    bank: false,
  });

  const rawBanks = useRef(null);
  const fetchErrorCountRef = useRef(0);

  useLayoutEffect(() => {
    update({ page: "Can't find bank" });
  }, []);

  const fetchBanks = useCallback(async () => {
    setIsFetchingBanks(true);
    try {
      const { data } = await api.getAllBanks(switchCountryCode(currency), public_key);
      rawBanks.current = data || [];
    } catch (error) {
      fetchErrorCountRef.current++;
    } finally {
      const filteredBanks = rawBanks.current?.map((bank) => bank) || [];
      setBanks(filteredBanks);
      setIsFetchingBanks(false);
    }
  }, []);

  useEffect(() => {
    if (!rawBanks.current) {
      fetchBanks();
    }
  }, [banks, fetchBanks]);

  return (
    <div className="kpy-col__bank-unavailable">
      <section className="kpy-col__header" id="kpy-col__header">
        <img src={BankUnavailable} alt="bank unavailable" />

        <h1>I can't find my bank</h1>
      </section>
      <p>
        We support only a few banks for this payment method at the moment. If
        your bank is not on the list, we can prioritize adding it to our list.
      </p>

      {submitted ? (
        <p className="new-bank-submitted">
          {" "}
          ✓ Thanks. We have received your request
        </p>
      ) : (
        <section className="new-bank-input">
          <div className="input-container">
            <ListDropdown
              type="bank"
              active={dropdown.bank}
              setActive={setDropdown}
              value={selectedBank}
              setValue={setSelectedBank}
              refetchList={() => fetchErrorCountRef.current < 3 ? fetchBanks() : null}
              isFetching={isFetchingBanks}
              list={banks}
              showFinalItemAsDefault
              className="kpy-col-input bank-dropdown new-bank-dropdown"
              finalItem={
                <input
                  type="text"
                  className="kpy-col-input new-bank-dropdown"
                  name="new-bank"
                  placeholder="Enter your bank"
                  onChange={(e) => setSelectedBank(e.target.value)}
                />
              }
            />
            <button
              type="button"
              className="kpy-col-btn"
              onClick={async () => {
                try {
                  const response = await api.requestProcessor({ bank_name: selectedBank });
                  if (response.status) return setSubmitted(true);
                  setError(true);
                  setTimeout(() => {
                    setError(false);
                  }, 2000);
                } catch (error) {
                  setError(true);
                  setTimeout(() => {
                    setError(false);
                  }, 2000);
                }
              }}
            >
              Submit
            </button>
          </div>
          <p id="bank-number-error" className="sr-only">
            There was a problem submitting your bank request. Please try again.
          </p>
          {isError && (
            <span className="kpy-input-field-error">
              There was a problem submitting your bank request. Please try
              again.
            </span>
          )}
        </section>
      )}
      <p>In the meantime, you can try a different method.</p>
    </div>
  );
};

export default BankNotAvailable;
