import React, { useEffect, useState } from 'react';
import {
  failedInsufficientFundsCard,
  issuedVirtualCard,
  success3DSCard,
  successAVSCard,
  successCardEnrollCard,
  successNoAuthCard,
  successOTPCard,
  successPINCard
} from '../../../mocks/input_mock';
import { modalStore } from '../../../state';

export default function TestCardScenarios() {
  const tabletScreenSize = 600;
  const firstTestId = 0;
  const { update } = modalStore();
  const [activeTestId, setActiveTestId] = useState<number>(firstTestId);
  const [isDesktopView, setIsDesktopView] = useState<false | true>(true);

  useEffect(() => {
    if (document.body.clientWidth <= tabletScreenSize) {
      setIsDesktopView(false);
    }
  }, []);

  const testSetsArray = [
    { id: 0, description: 'Virtual card (Issuing)', testCard: issuedVirtualCard },
    { id: 1, description: 'Success (no authentication)', testCard: successNoAuthCard },
    { id: 2, description: 'Success (with PIN)', testCard: successPINCard },
    { id: 3, description: 'Success (with OTP)', testCard: successOTPCard },
    { id: 4, description: 'Success (with 3D Secure)', testCard: success3DSCard },
    { id: 5, description: 'Success (with AVS)', testCard: successAVSCard },
    { id: 6, description: 'Success (with Card Enroll)', testCard: successCardEnrollCard },
    { id: 7, description: 'Failed (Insufficient funds)', testCard: failedInsufficientFundsCard }
  ];

  return (
    <div className="test-payments">
      <p className="test-payments__title">Please select a payment scenario to simulate the expected payment experience.</p>
      {isDesktopView ? (
        <ul className="test-payments__list">
          {testSetsArray &&
            testSetsArray.map(testSet => (
              <li className="test-payments__item" key={testSet.id}>
                <button
                  onClick={() => {
                    update({ testCard: testSet.testCard });
                    setActiveTestId(testSet.id);
                  }}
                  className={`${testSet.id === activeTestId && 'active'}`}
                >
                  {testSet.description}
                </button>
              </li>
            ))}
        </ul>
      ) : (
        <div className="test-payments__dropdown-wrapper">
          <select
            className="test-payments__dropdown"
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => update({ testCard: JSON.parse(e.target.value) })}
          >
            {testSetsArray &&
              testSetsArray.map(testSet => (
                <option key={testSet.id} value={JSON.stringify(testSet.testCard)}>
                  {testSet.description}
                </option>
              ))}
          </select>
        </div>
      )}
    </div>
  );
}
