import React, { useState, useLayoutEffect, useRef, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import APIRequest from '../services/api-service';
import { sendMessage, modalStore } from '../state';
import { formatAmount } from '../utils/formats';

import paycard from '../assets/pay-card.svg';
import loaderIcon from '../assets/kpy-loader.svg';
import cardlogo from '../assets/card-logo.svg';

import '../style/main.scss';

interface RouteParams {
  transactionReference: string;
}

const api = new APIRequest();

function ThreeDSTest() {
  const history = useNavigate();
  const { loader } = modalStore();
  const { transactionReference: reference } = useParams<RouteParams>();

  const [transactionDetails, setTransactionDetails] = useState({
    available: false,
    amount_charged: '',
    currency: ''
  });
  const [passwordState, setPasswordState] = useState({
    value: '1234'
  });
  const submitButtonRef = useRef<HTMLButtonElement | null>(null);

  const submitThreeDSForm = useRef(e => {
    // Enter key triggers the authorization
    if (e.keyCode === 13) {
      const submitButton = submitButtonRef.current;
      if (!submitButton?.classList.contains('disabled')) {
        submitButton?.click();
      }
      return;
    }
  });

  useLayoutEffect(() => {
    sendMessage('QUERY_TRANSACTION', async () => {
      const response = await api.queryCardCharge({
        reference,
        env: process.env.VITE_ENV_TYPE
      });
      const {
        data: { amount_charged, currency }
      } = response;
      setTransactionDetails({
        available: true,
        amount_charged,
        currency
      });
      return response;
    });
  }, [reference]);

  useEffect(() => {
    const submitThreeDS = submitThreeDSForm.current;
    window.addEventListener('keydown', submitThreeDS);

    return () => window.removeEventListener('keydown', submitThreeDS);
  }, []);

  useEffect(() => {
    if (transactionDetails.available) {
      document.getElementById('threeds-password')?.focus();
    }
  }, [transactionDetails.available]);

  const authorizeTestPayment = () => {
    if (passwordState.value.length === 0) return;

    return sendMessage('AUTH_THREEDS_TEST', async () => {
      const response = await api.authorizeThreedsTest({
        reference,
        pin: passwordState.value,
        env: process.env.VITE_ENV_TYPE
      });
      history(`/charge-card/verify/${reference}`);
      return response;
    });
  };

  return (
    <>
      <div className="kpy__root-container">
        <div className="secured__banner">
          <img src="https://korablobstorage.blob.core.windows.net/modal-bucket/Safety.svg" alt="" />
          <p>
            Secured by <strong>Korapay</strong>
          </p>
        </div>
        <div className="kpy-col__container">
          <>
            <section className="kpy-col__header" id="kpy-col__header">
              <div className="card-transfer--icon">
                <img src={paycard} alt="card icon" />
              </div>

              <h1>
                Pay {transactionDetails.currency} {transactionDetails.amount_charged && formatAmount(transactionDetails.amount_charged)}
              </h1>
            </section>

            <div className="kpy-col__pay-form --threeds-test">
              <section className="channel_container">
                <div className="kpy-col-input-field">
                  <label htmlFor="threeds-password" id="" className="kpy-col-label">
                    Please submit your Korapay test password.
                  </label>
                  <input
                    aria-label="threeds-password"
                    className="kpy-col-input --card-details"
                    name="threeds-password"
                    id="threeds-password"
                    type="password"
                    pattern="^[0-9]*$"
                    inputMode="numeric"
                    readOnly
                    value={passwordState.value}
                    onChange={event => {
                      setPasswordState(prevPasswordState => ({
                        ...prevPasswordState,
                        value: event.target.value
                      }));
                    }}
                    placeholder="****"
                  />
                </div>
              </section>
              <button
                className={`kpy-col-btn ${passwordState.value.length === 0 ? 'disabled' : ''}`}
                ref={submitButtonRef}
                aria-disabled={passwordState.value.length === 0}
                onClick={authorizeTestPayment}
              >
                <span>Submit</span>
              </button>
            </div>
          </>
          <div
            className={
              !transactionDetails.available || (transactionDetails.available && loader)
                ? 'kpy--modal--inner display-block'
                : 'kpy--modal--inner display-none'
            }
          >
            <div className="kpy--modal-content">
              <div className="kpy--modal--loader kpy--loader">
                <img src={loaderIcon} alt="Loading" />
                {!transactionDetails.available && <p>Loading your 3DS authorization...</p>}
              </div>
            </div>
          </div>
        </div>
        <div className="secure-icons">
          <img src={cardlogo} alt="Verified by Mastercard Secure and Visa" />
        </div>
      </div>
    </>
  );
}

export default ThreeDSTest;
