import { useRef, useState, useEffect, ReactNode } from "react";
import question from "../../assets/question-icon.svg";


interface InputToolTipProps {
  type: string;
  message: ReactNode;
  image?: ReactNode;
  full?: boolean;
  dark?: boolean;
}

function InputToolTip({ type, message, image= null, full, dark = false }: InputToolTipProps) {
  const [isOpen, setIsOpen] = useState(false);
  const tooltipButtonElement = useRef<null | HTMLButtonElement>(null);
  const tooltipElement = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    const element = tooltipElement.current as HTMLDivElement;
    const tooltipButton = tooltipButtonElement.current;
    if (element) {
      if (isOpen) {
        element.classList.add("visible");
       (element.parentNode as HTMLDivElement).classList.add("visible");
        element.focus();
        return;
      }
      element.classList.remove("visible");
      (element.parentNode as HTMLDivElement).classList.remove("visible");
      tooltipButton?.focus();
    }
  }, [isOpen]);

  const closeKeyboard = useRef((e: any) => {
    // Escape button closes the tooltip
    if (e.keyCode === 27) {
      setIsOpen(false);
      return;
    }
    // Spacebar on close button closes the tooltip
    if (
      e.keyCode === 32 &&
      tooltipElement.current &&
      e.target ===
        tooltipElement.current.childNodes[
          tooltipElement.current.childNodes.length - 1
        ]
    ) {
      setIsOpen(false);
      return;
    }
    // Tabbing forwards on the Close button inside modal returns to the Main text
    if (
      e.keyCode === 9 &&
      tooltipElement.current &&
      document.activeElement ===
        tooltipElement.current.childNodes[
          tooltipElement.current.childNodes.length - 1
        ]
    ) {
      (tooltipButtonElement.current as HTMLButtonElement).focus();
      return;
    }
  });

  const toggleKeyboard = useRef((e: any) => {
    e.preventDefault();
    const path = e?.path || e?.composedPath?.() || e.target;
    // Spacebar opens the tooltip
    if (e.keyCode === 32 && path.includes(tooltipButtonElement.current)) {
      setIsOpen((prevIsOpen) => !prevIsOpen);
    }
  });

  const closeOuter = useRef((e: any) => {
    const path = e?.path || e?.composedPath?.() || e.target;
    if (
      e.target !== tooltipButtonElement?.current?.childNodes[0] &&
      Array.isArray(path) &&
      !path.includes(tooltipElement.current?.parentNode || null)
    ) {
      setIsOpen(false);
    }
  });

  useEffect(() => {
    const closeOuterFunc = closeOuter.current;
    const closeKeyboardFunc = closeKeyboard.current;
    const toggleKeyboardFunc = toggleKeyboard.current;

    // Clicking outside closes the tooltip
    window.addEventListener("click", closeOuterFunc);
    window.addEventListener("keydown", closeKeyboardFunc);
    window.addEventListener("keyup", toggleKeyboardFunc);

    return () => {
      window.removeEventListener("click", closeOuterFunc);
      window.removeEventListener("keydown", closeKeyboardFunc);
    };
  }, []);

  return (
    <>
      <button
        className="kpy-col__input-tooltip"
        tabIndex={0}
        aria-label={`more ${type} information`}
        onClick={() => setIsOpen((prevIsOpen) => !prevIsOpen)}
        aria-expanded={isOpen}
        ref={tooltipButtonElement}
      >
        <img
          src={question}
          alt="question icon"
          className="input-tooltip--image"
          aria-hidden
        />
      </button>

      {isOpen && (
        <div
          className={`kpy-col__input-tooltip--message ${
            full ? "full-width" : ""
          } ${dark ? "dark" : ""}`}
        >
          <div
            className="input-tooltip"
            role="tooltip"
            tabIndex={0}
            aria-labelledby={`${type}-input-tooltip`}
            ref={tooltipElement}
          >
            {message}
            {image}
            <button
              className="close"
              aria-label="close tooltip"
              onClick={() => setIsOpen(false)}
            >
              &times;
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default InputToolTip;
