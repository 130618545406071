import React, { useEffect, useRef } from 'react';

import infoIcon from '../../../assets/delayed-icon.svg';
import { modalStore, sendMessage } from '../../../state';
import { formatAmount } from '../../../utils';
import APIRequest from '../../../services/api-service';

const mobilemoneyQueryInterval = Number(process.env.VITE_MOBILE_MONEY_QUERY_INTERVAL_IN_MILLISECONDS || 60000);
const api = new APIRequest();

export default function PaymentQuery() {
  const { amount, currency, stage, reference, fees, public_key } = modalStore();
  const confirmPayment = useRef<number>();

  const checkTransaction = () => {
    sendMessage('QUERY_MOBILE_MONEY_EGANOW', () =>
      api.queryMobileMoneyEganow({
        reference,
        env: process.env.VITE_ENV_TYPE,
        public_key
      })
    );
  };

  useEffect(() => {
    confirmPayment.current = window.setInterval(() => {
      checkTransaction();
    }, mobilemoneyQueryInterval);

    return () => {
      clearInterval(confirmPayment.current);
    };
  }, []);

  return (
    <section className="payment-query-container">
      <img src={infoIcon} alt="payment query information icon" />
      <h5>
        There is a delay in confirming your payment of {currency}{' '}
        {formatAmount(parseFloat(amount) + parseFloat(fees?.[stage]?.fee || 0) + parseFloat(fees?.[stage]?.vat || 0))}
      </h5>
      <p>You will get a confirmation once we receive your payment.</p>
      <i>
        <div className="oscillate-loader" />
      </i>
      <p>Confirming Payment...</p>
    </section>
  );
}
