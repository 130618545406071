import { useEffect, useState } from 'react';
import { api } from '../state/index';
import { ClientJS } from 'clientjs';

const useUniqueID = () => {
  const client = new ClientJS();
  const fingerprint = client.getFingerprint();
  const store = api.getState();
  const [uniqueID, setUniqueID] = useState('');

  useEffect(() => {
    const generateUniqueID = () => {
      const email = store?.customer?.email || '';
      const merchantId = store?.paymentLink?.merchant_id;
      const idString = `${fingerprint}/${merchantId}/${email}`;
      setUniqueID(idString);
    };

    generateUniqueID();
  }, []);

  return uniqueID;
};

export default useUniqueID;
