import React from 'react';
import { modalStore } from '../state';

export default function SessionEnd() {
  const { sessionAttempt, errorMessage } = modalStore();
  const sessionlimit = process.env.VITE_SESSION_ATTEMPT_LIMIT || 5;
  return (
    <section className="kpy-col-result session-end">
      <h3 className="result-title">Session Ended</h3>
      {!(sessionAttempt > sessionlimit) ? (
        <p>{errorMessage || 'You may close this tab on your browser.'}</p>
      ) : (
        <p>You have reached the maximum payment attempt for this transaction, Please start a new transaction to pay</p>
      )}
    </section>
  );
}
