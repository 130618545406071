import React, { useEffect, useRef } from 'react';
import { modalStore } from '../../../state';
import { formatPhoneNumber, countries, ValidatePhoneNumber } from '../../../utils/formats';
import lock from '../../../assets/lock.svg';


export default function MobileMoneyInit({ country, setCountry, chargePhoneNumber, phoneNumber, setPhoneNumber }) {
  const submitButtonRef = useRef(null);
 const { paymentLink } = modalStore();
  const submitMobileMoneyInitForm = useRef(e => {
    // Enter key triggers the authorization
    if (e.keyCode === 13) {
      const submitButton = submitButtonRef.current;
      if (!submitButton.classList.contains('disabled')) {
        submitButton.click();
      }
      return;
    }
  });

  const handleDisabled = () => {
    if (phoneNumber === '') return true;
    if (phoneNumber?.length < 2) return true;
    if (!ValidatePhoneNumber(country, phoneNumber)) return true;
    return false;
  };

  useEffect(() => {
    const submitPhone = submitMobileMoneyInitForm.current;
    window.addEventListener('keydown', submitPhone);

    document.getElementById('mobile-money-phone-number').focus();
    return () => window.removeEventListener('keydown', submitPhone);
  }, []);

  const getDescription = (country) => {
    switch (country) {
      case '254':
        return {
          line1: 'Enter a valid mobile money number',
          line2: 'below to process your payment.',
          CTA: 'Pay with Mobile Money'
        };
      case '233':
      case '237':
      case '225':
        return {
          line1: 'Enter a valid mobile money number below',
          line2: 'to process your payment.',
          CTA: 'Pay with Mobile Money'
        };
      case '234':
        return {
          line1: 'Enter a valid PayAttitude number below',
          line2: 'to process your payment.',
          CTA: 'Pay with PayAttitude'
        };
      default:
        return {
          line1: 'Enter a valid mobile money number below',
          line2: 'to process your payment.',
          CTA: 'Pay with Mobile Money'
        };
    }
  };

  return (
    <div className="kpy-col__pay-form">
      <p className="details-head" data-testid="details-head">
        {getDescription(country)?.line1}
        <br />
        {getDescription(country)?.line2}
      </p>
      <section className="channel_container">
        <div className="kpy-col-input-field">
          <label htmlFor="mobile-money-phone-number" className="kpy-col-label">
            Enter Phone Number
          </label>
          <div className="phone-input-field">
            <div className="input-group-prepend">
              <select
                value={country}
                aria-label="phone-number-select"
                name="phone-number"
                component="select"
                disabled
                onChange={e => setCountry(e.currentTarget.value)}
                className={phoneNumber && !ValidatePhoneNumber(country, phoneNumber) ? 'error-input' : ''}
              >
                {Object.keys(countries).map(item => (
                  <option value={item} key={countries[item]?.country}>
                    {item}
                  </option>
                ))}
              </select>
            </div>
            <input
              aria-label="mobile-money-phone-number"
              kpy-name-input="true"
              className={`kpy-col-input --mobile-money-details ${
                phoneNumber && !ValidatePhoneNumber(country, phoneNumber) && 'error-input'
              }`}
              data-testid="mobile-money-phone-number"
              id="mobile-money-phone-number"
              name="mobile-money-phone-number"
              type="text"
              placeholder=""
              value={formatPhoneNumber(phoneNumber).slice(1)}
              onChange={event => {
                setPhoneNumber(`0${event.target.value.replace(/\s+/g, '')}`);
              }}
            />
          </div>
          {phoneNumber && !ValidatePhoneNumber(country, phoneNumber) && (
            <p className="kpy-input-field-error">Please enter a valid wallet number</p>
          )}
        </div>
      </section>
      <button
        aria-label="mobile-money-pay-btn"
        className="kpy-col-btn"
        onClick={chargePhoneNumber}
        ref={submitButtonRef}
        aria-disabled={handleDisabled()}
        disabled={handleDisabled()}
        style={{
          background: paymentLink?.checkout_customization?.primary_color,
          color: paymentLink?.checkout_customization?.secondary_color
        }}
      >
        <img src={lock} alt="lock icon" aria-hidden />
        <span>{getDescription(country)?.CTA}</span>
      </button>
    </div>
  );
}

