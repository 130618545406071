/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Help from '../../shared/help';

function BankTransferHelp({ goBack }) {
  const helpMessages = [
    'If you’re unable to find the account we generated on your banking app, kindly wait a few more minutes and check again. Or try a different banking app.',
    'Sometimes, bank transfers take a bit longer than usual. However, if you transferred an incorrect amount or we are unable to confirm your transfer eventually, a refund will be processed to your account.'
  ];

  return <Help helpMessages={helpMessages} goBack={goBack} description="Having trouble with this transfer?" />;
}

export default BankTransferHelp;
