import React, { useState } from 'react';
import check from '../../../assets/check-icon-circle.svg';
import { modalStore } from '../../../state';

const BankTransferIntro = ({ handleClickUnderstand }) => {
  const { payment_channels_details, paymentLink } = modalStore();
  const [isChecked, setIsChecked] = useState(false);
  return (
    <div className="kpy-col__bank-intro">
      <p className="bank-intro_title" data-testid="bank-intro-title" style={{ color: '#414f5f' }}>
        Before you make this transfer
      </p>
      <div className="bank-intro_container">
        <div className="bank-intro_item">
          <span className="check-icon">
            <img src={check} alt="check icon" aria-label="final step" />
          </span>
          <p>
            <strong>Transfer only the exact amount</strong>
            <span style={{ display: 'block' }}>Do not transfer an incorrect amount</span>
          </p>
        </div>
        <div className="bank-intro_item">
          <span className="check-icon">
            <img src={check} alt="check icon" aria-label="final step" />
          </span>
          <p>
            <strong>Do not save or reuse the account;</strong> it can only accept a single transfer.
          </p>
        </div>
        <div className="bank-intro_item">
          <span className="check-icon">
            <img src={check} alt="check icon" aria-label="final step" />
          </span>
          <p>
            The account expires after{' '}
            <strong>
              {payment_channels_details?.bank_transfer?.expiry_period_in_mins
                ? `${payment_channels_details?.bank_transfer?.expiry_period_in_mins} mins`
                : 'a fixed time'}
            </strong>
            .
          </p>
        </div>
        <div style={{ cursor: 'pointer', alignItems: 'center' }} onClick={() => setIsChecked(!isChecked)} className="bank-intro_item ">
          <input
            data-testid="i-understand-checkbox"
            aria-label="checkbox"
            type="checkbox"
            className="bank-intro_checkbox"
            onChange={e => setIsChecked(e.target.checked)}
            checked={isChecked}
          />
          <p className="accept-terms">I understand these instructions.</p>
        </div>
        <button
          style={{
            background: paymentLink?.checkout_customization?.primary_color,
            color: paymentLink?.checkout_customization?.secondary_color
          }}
          disabled={!isChecked}
          className="kpy-col-btn"
          onClick={handleClickUnderstand}
        >
          <span>Continue</span>
        </button>
      </div>
    </div>
  );
};

export default BankTransferIntro;
