import React, { useState, useRef, useEffect, useCallback, useLayoutEffect } from 'react';
import { stringify } from 'flatted';
import { BroadcastChannel } from 'broadcast-channel';
import { modalStore, sendMessage } from '../../../state';
import { Mixpanel } from '../../../utils/mixpanel';
import APIRequest from '../../../services/api-service';
import { calculateThreeDSTime } from '../../../utils/formats';

import ThreeDSIframe from '../components/threeds-modal';

const api = new APIRequest();

interface ThreeDSFormType {
  resetCardStage: () => void;
}

interface BroadcastElementsTypes {
  channel: null | BroadcastChannel;
  interval: null | NodeJS.Timer;
}

const ThreeDSForm = ({ resetCardStage }: ThreeDSFormType) => {
  const {
    testCard,
    update,
    reference,
    card,
    page,
    allowMultipleChannels,
    paymentLink
  } = modalStore();

  useLayoutEffect(() => {
    update({ page: '3DS Intro' });
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [broadcastElements, setBroadCastElements] = useState<BroadcastElementsTypes>({
    channel: null,
    interval: null
  });
  const [threeDS, setThreeDS] = useState({
    visible: false,
    type: '',
    content: '',
    waitElapsed: false
  });
  const [checkingTransaction, setCheckingTransaction] = useState(false);

  const paymentConfirmed = useRef(false);
  const timerInterval = useRef<null | any>(null);

  const proceedTo3DS = useRef(e => {
    // Enter key triggers the authorization
    if (e.keyCode === 13) {
      (document.querySelector('.kpy-col-btn.--threeds-auth') as HTMLButtonElement)?.click();
      return;
    }
  });

  useEffect(() => {
    return () => {
      clearInterval(broadcastElements?.interval);
      broadcastElements?.channel?.close();
    };
  }, [broadcastElements]);

  const trigger3DSScreen = () => {
    Mixpanel.track('Proceeded to 3DS Authorization');
    const broadcast = new BroadcastChannel('KPY_ATHENA');
    const threeDSUrl = `${process.env.VITE_MODAL_SERVER || ''}/charge-card/${testCard ? 'threeds-test' : 'threeds-type'}/${reference}`;
    const threeDSWindow = window.open(threeDSUrl);
    const stringifyWindow = stringify(threeDSWindow);
    threeDSWindow?.focus();

    setThreeDS(prevThreeDS => ({
      ...prevThreeDS,
      visible: true
    }));
    update({ modalState: 'threeds', page: 'Awaiting Authorization' });
    const broadcastInterval = setInterval(() => {
      broadcast.postMessage(stringifyWindow);
    }, 300);

    setCheckingTransaction(true);
    setBroadCastElements(() => ({
      channel: broadcast,
      interval: broadcastInterval
    }));
  };

  const verifyTransaction = useCallback(
    async (type: string) => {
      setIsModalOpen(false);
      if (!paymentConfirmed.current) {
        return sendMessage('QUERY_CARD_CHARGE', async () => {
          const response = await api.queryCardCharge({
            reference,
            env: process.env.VITE_ENV_TYPE
          });
          // Prevent checking transaction multiple times
          setCheckingTransaction(false);
          paymentConfirmed.current = true;
          // Return 'Change Payment' button for incomplete transactions in card init form
          if (type === 'incomplete') {
            setTimeout(() =>
              update({
                buttonAction: response?.data?.status !== 'success' ? 'changePayment' : 'close'
              })
            );
          }
          update({
            hideHeading: type === 'complete'
          });
          return response;
        });
      }
    },
    [reference, update]
  );

  useEffect(() => {
    const proceedTo3DSFunc = proceedTo3DS.current;
    window.addEventListener('keydown', proceedTo3DSFunc);
    document.querySelector('.kpy-col-btn.--threeds-auth')?.focus();
    return () => window.removeEventListener('keydown', proceedTo3DSFunc);
  }, []);

  useEffect(() => {
    if (threeDS.visible) {
      const startTime = new Date();
      const threeDSDeadline = new Date();
      threeDSDeadline.setMinutes(threeDSDeadline.getMinutes() + 10);

      timerInterval.current = setInterval(() => {
        const timerValue = calculateThreeDSTime(threeDSDeadline, startTime);
        // Show Try again button after some time
        if (timerValue.minutesElapsed > 0.5) {
          setThreeDS(prevThreeDS => ({
            ...prevThreeDS,
            waitElapsed: true
          }));
        }
        // Check transaction after the complete wait period
        if (timerValue.text === 'expired') {
          clearInterval(timerInterval.current);
          update({ page: 'Incomplete Authorization' });
          Mixpanel.track('Incomplete 3DS Authorization');
          verifyTransaction('complete');
        }
        return;
      }, 5000);
    }

    return () => clearInterval(timerInterval.current);
  }, [threeDS.visible, verifyTransaction, update]);

  return (
    <div className={`kpy-col__pay-form ${!(!checkingTransaction && paymentConfirmed.current) ? '--threeds' : ''}`}>
      {!threeDS.visible ? (
        <>
          <p className="details-content">Please click the button below to complete this payment with your bank.</p>
          {testCard ? (
            <a>
              <button
                className="kpy-col-btn --threeds-auth"
                onClick={() => {
                  trigger3DSScreen();
                }}
              >
                <span>Proceed</span>
              </button>
            </a>
          ) : (
            <>
              {/* For Processors that provide a URL */}
              {/* {(!threeDS.type || threeDS.type === "url") && (
                <button
                  className={`kpy-col-btn --threeds-auth ${
                    !threeDS.type ? "disabled" : ""
                  }`}
                  aria-disabled={!threeDS.type}
                  onClick={() => {
                    if (threeDS.type) {
                      trigger3DSScreen();
                      setIsModalOpen(true);
                    }
                  }}
                >
                  <span>Proceed</span>
                </button>
              )} */}

              <a>
                <button
                  style={{
                    background: paymentLink?.checkout_customization?.primary_color,
                    color: paymentLink?.checkout_customization?.secondary_color
                  }}
                  className="kpy-col-btn --threeds-auth"
                  onClick={() => {
                    trigger3DSScreen();
                  }}
                >
                  <span>Proceed</span>
                </button>
              </a>
            </>
          )}
        </>
      ) : (
        <>
          {!checkingTransaction && paymentConfirmed.current ? (
            <>
              <div className="custom-info">
                <section className="kpy-col__header --help">
                  <h1>Authorization was not completed.</h1>
                  <p>Please try paying again.</p>
                </section>

                <div className="button-group">
                  <button
                    onClick={() => {
                      resetCardStage();
                      Mixpanel.track('Try again selected', {
                        'Source Page': `${page} page`
                      });
                      update({
                        hideHeading: false,
                        buttonAction: 'changePayment',
                        modalState: 'loaded',
                        card: {
                          ...card,
                          stage: 'init',
                          cardType: ''
                        }
                      });
                    }}
                  >
                    Try again
                  </button>
                  {allowMultipleChannels && (
                    <button
                      onClick={() => {
                        resetCardStage();
                        Mixpanel.track('Attempted to switch payment method', {
                          'Source Page': `${page} page`
                        });
                        update({
                          modalState: 'loaded',
                          feedback: false,
                          hideHeading: true,
                          stage: 'init',
                          page: 'Payment Method',
                          buttonAction: 'close',
                          testCard: null
                        });
                      }}
                    >
                      Use a different method
                    </button>
                  )}
                </div>
              </div>
            </>
          ) : (
            <>
              <section className="details-footer">
                <div className="confirmation-spinner visible">
                  <span />
                </div>
                <p className="details-content --footer">Checking your transaction</p>
              </section>

              {threeDS.waitElapsed && (
                <div className="retry-auth">
                  <p>If the tab for authentication was mistakenly closed, you can try again.</p>
                  <div className="button-group">
                    <button
                      onClick={async () => {
                        // Check transaction before trying another transaction
                        await verifyTransaction('incomplete');
                        resetCardStage();
                        Mixpanel.track('Try again selected', {
                          'Source Page': `${page} page`
                        });
                      }}
                    >
                      Try again
                    </button>
                  </div>
                </div>
              )}
            </>
          )}
        </>
      )}
      {isModalOpen && <ThreeDSIframe type={threeDS.type} url={threeDS.content} cancelThreeDS={() => verifyTransaction('complete')} />}
    </div>
  );
};

export default ThreeDSForm;
