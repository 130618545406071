import { ReactNode, useEffect, useRef } from 'react';
import arrow from '../../assets/accordion-arrow.svg';

interface SandboxToolTipTypes {
  content: ReactNode;
  footerLink?: string;
  footerText?: string;
  header?: string;
  openAutomaticaly?: boolean;
}

function SandboxToolTip({
  content,
  footerLink = 'https://developers.korapay.com',
  footerText = 'See our documentation for more scenarios',
  header = 'Test Instructions',
  openAutomaticaly = true
}: SandboxToolTipTypes) {
  const accordionRef = useRef<null | HTMLDetailsElement>(null);
  useEffect(() => {
    if (openAutomaticaly) {
      accordionRef.current?.setAttribute('open', 'true');
    }
  }, []);

  return (
    <>
      <details className="sandbox-accordion" ref={accordionRef}>
        <summary>
          <section>{header}</section>
          <img className="toggler-arrow" src={arrow} alt="" />
        </summary>
        <section className="accordion-content">
          {content}
          <div className="footer">
            <a target='_blank' href={footerLink}>{footerText}</a>
          </div>
        </section>
      </details>
      <section className="accordion-backdrop" onClick={() => (accordionRef.current as HTMLDetailsElement).removeAttribute('open')} />
    </>
  );
}

export default SandboxToolTip;
