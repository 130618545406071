/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { Mixpanel, calculateTime } from '../../utils';
import { modalStore, sendMessage } from '../../state';
import APIRequest from '../../services/api-service';

import BankTransfer from './bank-transfer-actions/bank-transfer';
import ExpiredBank from './bank-transfer-actions/expired-bank';

import BankTransferHelp from './bank-transfer-actions/bank-help';

const api = new APIRequest();

function BankTransferHome() {
  const { update, reference, bankDetails, stage, public_key } = modalStore();
  const bankTransferQueryInterevalinms = process.env.VITE_BANK_TRANSFER_QUERY_INTERVAL || 180000;
  const paymentConfirmed = useRef(false);
  const timerInterval = useRef<null | NodeJS.Timeout>(null);

  const [bankState, setBankState] = useState('');
  const [time, setTime] = useState(calculateTime(bankDetails?.expiry_date_in_utc));

  useLayoutEffect(() => {
    update({ page: 'BankTransfer' });
  }, []);

  useLayoutEffect(() => {
    if (stage === 'bank_transfer_help_actions') {
      setBankState('help');
    }
  }, [stage]);

  useEffect(() => {
    if (timerInterval.current) {
      clearInterval(timerInterval.current);
    }

    const startTime = new Date();
    timerInterval.current = setInterval(() => {
      const timerValue = calculateTime(bankDetails?.expiry_date_in_utc, startTime);
      if (timerValue.text === 'expired') {
        Mixpanel.track('Payment terminated');
        clearInterval(timerInterval.current);
      }
      return setTime(timerValue);
    }, 1000);

    paymentConfirmed.current = false;

    return () => clearInterval(timerInterval.current);
  }, [bankDetails?.expiry_date_in_utc]);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (!time.confirmPayment) {
      const checkPayment = () =>
        setTime((prevState: any) => {
          return { ...prevState, confirmPayment: true };
        });
      interval = setInterval(checkPayment, Number(bankTransferQueryInterevalinms));
    }
    return () => clearInterval(interval);
  }, [bankDetails?.expiry_date_in_utc]);

  useEffect(() => {
    if (time.text === 'expired') {
      setBankState('expired');
      update({
        hideHeading: true
      });
    }
  }, [time.text]);

  useEffect(() => {
    const checkTransaction = () => {
      sendMessage('CONFIRM_BANKTRANSFER', () =>
        api.queryBankTransferCharge({
          reference,
          env: process.env.VITE_ENV_TYPE,
          public_key
        })
      );
    };

    if (time.confirmPayment && !paymentConfirmed.current) {
      // Check the status of payment before switching to expired bank view
      checkTransaction();
    }
  }, [time.confirmPayment]);

  const returnToBankTransfer = () => {
    if (time.text === 'expired') {
      setBankState('expired');
      update({
        buttonAction: 'helpActions',
        stage: 'bank_transfer',
        page: 'Termination'
      });
      return;
    }
    setBankState('');
    update({
      buttonAction: 'helpActions',
      stage: 'bank_transfer',
      page: 'Account generated',
      hideHeading: false
    });
  };

  switch (bankState) {
    case 'help':
      return <BankTransferHelp goBack={returnToBankTransfer} />;

    case 'expired':
      Mixpanel.track('Payment terminated');
      return <ExpiredBank updateBankStage={setBankState} />;

    default:
      return <BankTransfer time={time} bankDetails={bankDetails} />;
  }
}

export default BankTransferHome;
