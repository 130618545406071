import { useState } from "react";
import Stars from '../../assets/stars-banner.svg';
import Caret from '../../assets/dropdown-icon.svg';

const StarthereHeader = () => {
 const [showMore, setShowMore] = useState(false);
 const handleButtonClick = () => {
    window.open('https://merchant.korapay.com/auth/signup?utm_source=referral&utm_medium=payment_link', '_blank');
  };

  return (
    <div className={`redirect_banner ${showMore ? 'dropdown-banner': ''}`}>
      <div>
        <p>
          <img src={Stars} alt='stars' />
          <strong>Have a business?</strong>
          <span className='dropdown-button' onClick={()=>setShowMore(!showMore)}><img className='dropdown-icon' src={Caret}  alt="caret icon"/></span>
        </p>
        <span className={showMore ? '' : 'scale-africa'}>You can accept payments across Africa too</span>
      </div>
      <button onClick={handleButtonClick}>Start Here</button>
    </div>
  )
}

export default StarthereHeader;