import React, { useLayoutEffect } from "react";
import Footer from "./footer";
import { handleOutgoing } from "../services/external-hooks";
import { modalStore } from "../state";
import { Mixpanel, formatAmount } from "../utils";

interface DisableOutgoing {
  disableOutgoing?: boolean;
}

export default function Feedback({ disableOutgoing }: DisableOutgoing) {
  const {
    modalState,
    feedbackImage,
    errorMessage,
    successMessage,
    update,
    amount,
    currency,
    amount_paid,
    payment_reference,
    issueStatus,
    outgoingError,
    isSelfClosing,
    amount_reversed,
    transactionLimit,
    outgoingStatus,
    storeCardForFutureUse,
    cardNotTokenized,
    isResponseViaApi,
    fees,
    stage
  } = modalStore();

  useLayoutEffect(() => {
    switch (modalState.toLowerCase()) {
      case 'failed':
        Mixpanel.track('Payment failed', {
          'Reason for failure': errorMessage
        });
        update({ page: 'Payment Failed' });
        break;
      case 'success':
        Mixpanel.track('Payment successful', null);
        update({ page: 'Payment Successful' });
        break;
      case 'return':
        Mixpanel.track('Payment rejected', {
          'Amount expected': amount,
          'Amount paid': amount_paid
        });
        update({ page: 'return' });
        break;
      case 'return excess':
        Mixpanel.track('Payment overpaid', {
          'Amount expected': amount,
          'Amount paid': amount_paid
        });
        update({ page: 'return excess' });
        break;
      case 'issue detected':
        Mixpanel.track('Checkout - Issue Detected', {
          'Issue detected - status': issueStatus,
          'Payment Reference': payment_reference
        });
        break;
      default:
        Mixpanel.track(`Something's Wrong page view`, {
          reason: errorMessage,
          'Payment Reference': payment_reference
        });
        break;
    }
  }, []);

  useLayoutEffect(()=> {
    if (storeCardForFutureUse && cardNotTokenized) {
      Mixpanel.track(`Tokenization Failure`, {
        'Status' : 'Successful transaction but card not tokenized',
      });
    }
  }, [])

  if (isSelfClosing) {
    setTimeout(() => {
      update({
        modalState: "paymentLink",
        feedback: false,
        stage: "init",
        buttonAction: "close",
        pay_with_bank: null,
        hideHeading: true,
        headingImage: null,
        testCard: null,
        bankDetails: null,
        sandboxInstructions: null,
        isSelfClosing: false,
      });
    }, 5000);
  }

  const feedbackMessage = () => {
    if (!disableOutgoing) {
      /**
       * PS: It is VERY important that non-sensitive imformation is sent
       * in this function. Only the following information should be sent:
       */
      handleOutgoing(modalState, {
        message: outgoingError,
        amount,
        amount_paid,
        reference: payment_reference,
        status: outgoingStatus || modalState
      });
    }
    switch (modalState.toLowerCase()) {
      case "success":
        return (
          <>
            {successMessage ?? `Your payment of  ${currency} ${formatAmount(amount_paid)} has been received`}
            {storeCardForFutureUse && cardNotTokenized && (
              <p className="try-again-warning">Please be informed that we were unable to save your card following this transaction.</p>
            )}
          </>
        );
      case "return":
        return (
          <>
            {transactionLimit ? (
              <>
                <p>
                  Your transaction amount should be between {currency} {formatAmount(transactionLimit?.minimum_amount)} and {currency}{' '}
                  {formatAmount(transactionLimit?.maximum_amount)}. Please, try again with a different amount.
                </p>
                <p>
                  We would automatically reverse{' '}
                  <strong>
                    {currency} {formatAmount(amount_reversed)}
                  </strong>
                </p>
              </>
            ) : (
              <p>
                It seems you made a payment of{' '}
                <strong>{isResponseViaApi ? 'an incorrect amount' : `${currency} ${formatAmount(amount_paid)}`}</strong> instead of{' '}
                <strong>
                  {currency} {isResponseViaApi ? formatAmount(parseFloat(amount) + parseFloat(fees?.[stage]?.fee || 0) + parseFloat(fees?.[stage]?.vat || 0)) : formatAmount(amount)}
                </strong>
                . We would automatically reverse{' '}
                <strong>{isResponseViaApi ? 'the amount' : `${currency} ${formatAmount(amount_reversed)}`}</strong>
              </p>
            )}

            <p className="try-again-warning">
              Please do not make another transfer into the same account. Click ‘Try Again’ to generate a new account number for this
              transaction.
            </p>
          </>
        );
      case "return excess":
        return (
          <>
            <p>
              Your payment of {currency} {formatAmount(amount_paid)} has been
              received, however we expected {currency} {formatAmount(amount)}.
            </p>
            <p>
              We would automatically reverse {currency}{" "}
              {formatAmount(amount_reversed)}
            </p>
          </>
        );
      default:
        return errorMessage ?? "Sorry, your payment was not completed.";
    }
  };

  const handleFeedbackTitle = () => {
    if (!["return", "return excess"].includes(modalState)) return modalState;
    return modalState === "return"
      ? `Unsuccessful Transaction: Incorrect amount`
      : "success";
  };

  return (
    <>
      <section className="kpy-col-result">
        <img
          src={feedbackImage}
          className="result-img"
          alt="Transaction feedback"
        />
        <h3 className="result-title">{handleFeedbackTitle()}</h3>
        <p>
          {feedbackMessage()}
          {disableOutgoing && " You may now close this tab."}
        </p>
        {isSelfClosing && (
          <span className="kpy-col-message">
            Transaction will restart after 5 seconds
          </span>
        )}
      </section>
      
      <Footer />
    </>
  );
}
