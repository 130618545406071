import { useLayoutEffect, Dispatch, SetStateAction } from 'react';
import { modalStore } from '../../../state';
import { allowOnlyNumeric } from '../../../utils/formats';
import { authDetailsType } from '../cardtypes';
import InputToolTip from '../../shared/input-tooltip';
import cvvmessage from '../../../assets/cvv-message.svg';

interface PinformTypes {
  cvv: string;
  setAuthDetails: Dispatch<SetStateAction<authDetailsType>>;
  authorizeCharge: () => void;
}

const CvvForm = ({ cvv, setAuthDetails, authorizeCharge }: PinformTypes) => {
  const { testCard, update } = modalStore();

  useLayoutEffect(() => {
    update({ page: 'Card CVV Page' });
  }, []);

  return (
    <div className={`kpy-col__pay-form`}>
      <p className="details-head" id="cvv-information">
        Kindly enter the CVV number at the back of your card
      </p>
      <section className="channel_container">
        <div className="kpy-col-input-field">
          <label htmlFor="card-cvv" className="kpy-col-label">
            Enter your CVV
          </label>
          <div className="field-wrapper">
            <input
              aria-label="card-cvv"
              aria-describedby="card-cvv-message"
              className="kpy-col-input --card-details cvv_input"
              name="auth"
              id="card-cvv"
              type="text"
              pattern="^$|^[\d]+$"
              inputMode="numeric"
              maxLength={3}
              readOnly={Boolean(testCard)}
              value={cvv}
              onChange={event => {
                const value = event.target.value;
                const validChars = /^$|^[\d]+$/.test(value || '');
                if (!validChars) return;
                setAuthDetails(prevAuthDetails => ({
                  ...prevAuthDetails,
                  cvv: allowOnlyNumeric(value)
                }));
              }}
              placeholder="000"
            />
            <InputToolTip
              type="card-cvv"
              message={
                <p className="centered" id="card-cvv-input-tooltip">
                  Your security code is the <strong>3 or 4-digit</strong> number on the back of your card.
                </p>
              }
              image={
                <>
                  <img src={cvvmessage} alt="card-cvv illustration" aria-hidden />
                </>
              }
            />
          </div>
        </div>
      </section>
      <button disabled={cvv.length !== 3} className={`kpy-col-btn cvv-form-btn`} onClick={authorizeCharge}>
        <span>Authorize</span>
      </button>
    </div>
  );
};

export default CvvForm;
