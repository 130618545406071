import { StateType } from './bank-types';

export const initialState: StateType = {
  bankState: 'init',
  bankStyle: {
    accent: '',
    color: '',
    image: ''
  },
  isFetchingBanks: false,
  bankList: [],
  bankError: '',
  bankDetails: {
    bank: false,
    processor: '',
    bank_name: '',
    account_number: '',
    phone_number: '',
    date_of_birth: '',
    account_name: '',
    code: ''
  }
};
