import React, { useState, useEffect, useRef } from 'react';
import timeIcon from '../../../assets/timer-icon.svg';
import { modalStore, sendMessage } from '../../../state';
import APIRequest from '../../../services/api-service';

import { calculateTime } from '../../../utils/formats';

const api = new APIRequest();

export default function CheckTransaction({ retryRequest, country }) {
  const { public_key, reference, mobileMoneySecondLapse, resendLimitExceeded, canResendStk, update, currency } = modalStore();
  const mobilemoneyQueryInterval = process.env.VITE_MOBILE_MONEY_QUERY_INTERVAL_IN_MILLISECONDS || 180000;
  const paymentConfirmed = useRef(false);
  const confirmPayment = useRef(null);
  const timerInterval = useRef(null);
  const [time, setTime] = useState(calculateTime(new Date(mobileMoneySecondLapse)));

  useEffect(() => {
    if (timerInterval.current) {
      clearInterval(timerInterval.current);
    }

    const startTime = new Date();
    timerInterval.current = setInterval(() => {
      const timerValue = calculateTime(new Date(mobileMoneySecondLapse), startTime);
      if (timerValue.text === 'expired') {
        clearInterval(timerInterval.current);
      }
      return setTime(timerValue);
    }, 1000);

    paymentConfirmed.current = false;

    return () => {
      clearInterval(timerInterval.current);
    };
  }, [mobileMoneySecondLapse]);

  useEffect(() => {
    let interval
    if (!time.confirmPayment) {
      const checkPayment = () =>
        setTime(prevState => {
          return { ...prevState, confirmPayment: true };
        });
     interval = setInterval(checkPayment, 180000);
    }
    return () => clearInterval(interval)
  });

   const checkTransaction = () => {
    sendMessage('CONFIRM_MOBILE_MONEY', () =>
      api.queryMobileMoneyCharge({
        reference,
        env: process.env.VITE_ENV_TYPE,
        public_key
      })
    );
  };

  useEffect(() => {
    confirmPayment.current = setInterval(() => {
      checkTransaction();
    }, mobilemoneyQueryInterval);

    return () => {
      clearInterval(confirmPayment.current);
    };
  }, []);

  const getDescription = country => {
    switch (country) {
      case '233':
      case '254':
      case '237':
      case '225':
        return {
          line1: 'Enter your mobile money pin on your phone to authorize this payment.',
          line2: 'A request from your provider has been sent to your phone, please enter your PIN to authorize this payment.'
        };
      case '234':
        return {
          line1: 'Enter your PayAttitude PIN on your phone.',
          line2: 'A request from PayAttitude has been sent to your phone, please enter your PIN to authorize this payment.'
        };
      default:
        return {
          line1: '',
          line2: ''
        };
    }
  };

  const paymentQuery = () => {
    update({ loader: true });
    sendMessage('QUERY_MOBILE_MONEY_EGANOW', () =>
      api.queryMobileMoneyEganow({
        reference,
        env: process.env.VITE_ENV_TYPE,
        public_key
      })
    );
  };

  useEffect(() => {
    if (currency === 'GHS') {
      update({
        extraInstructions: (
          <div className="accordion-wrap">
            <p className="accordion-text">Complete your payment with these steps, for MTN users:</p>
            <ol className="accordion-highlight accordion-list-items">
              <li className="accordion-text">
                <span>1</span>Dial *170# on your phone
              </li>
              <li className="accordion-text">
                <span>2</span>Select 6 for Wallet.
              </li>
              <li className="accordion-text">
                <span>3</span>Choose 3 for My Approvals.
              </li>
              <li className="accordion-text">
                <span>4</span>Enter your Mobile Money PIN to approve the payment.
              </li>
            </ol>
            <p className="accordion-text">Telecel users:</p>
            <ol className="accordion-highlight accordion-list-items">
              <li className="accordion-text">
                <span>1</span>Dial *110# on your phone
              </li>
              <li className="accordion-text">
                <span>2</span>Select 6 for My Account.
              </li>
              <li className="accordion-text">
                <span>3</span>Choose 5 for My Approvals.
              </li>
              <li className="accordion-text">
                <span>4</span>Enter your Mobile Money PIN to approve the payment.
              </li>
            </ol>
          </div>
        ),
        extraInstructionsHeader: `Didn't receive a prompt?`,
        extraInstructionsfooterText: 'See our documentation for more help',
        showExtraInstructionsOnRender: false
      });
    }
  }, []);

  const sendRequest = () => {
    if (resendLimitExceeded) {
      return <p>Resend Limit exceeded</p>;
    }
    if (time?.text === 'expired') {
      return (
        <div>
          <button className="kpy-btn--transparent" onClick={retryRequest}>
            <img src={timeIcon} alt="time icon" aria-hidden height={12} />
            &nbsp;
            <p className="green-text">Resend request</p>
          </button>
        </div>
      );
    }

    return (
      <div>
        <img src={timeIcon} alt="time icon" aria-hidden height={12} />
        &nbsp;
        <p>Resend Request? Please wait {time?.text}</p>
      </div>
    );
  };

  return (
    <div className="kpy-col__pay-form --mobile-money">
      <section className="details-head">
        <p>
          <strong>Do not close this page untill you get a payment confirmation</strong>
        </p>
      </section>
      <section className="channel_container">
        <p>
          <strong>{getDescription(country)?.line1}</strong>
        </p>
        <p>{getDescription(country)?.line2}</p>
        {canResendStk && sendRequest()}
      </section>
      <section className="details-footer">
        {time?.text === 'expired' && !resendLimitExceeded && (
          <div className={`confirmation-spinner visible`}>
            <span />
          </div>
        )}
        <p>You will get a confirmation once we receive your payment.</p>
        <button onClick={paymentQuery} className="payment-query">
          I've sent the money
        </button>
      </section>
    </div>
  );
}
