import { modalStore } from '../../state';
import Copyable from './copyable';
import { formatAmount, switchPaymentChannelImage, switchPaymentChannelImageType, switchCountryCurrency} from '../../utils';


export default function Heading() {
  const {
    avatar,
    amount,
    currency,
    hideHeading, 
    stage, 
    fees, 
    headingImage,
    conversion, 
   } = modalStore();

  return (
    <>
      <section className="kpy-col__header" id="kpy-col__header" hidden={hideHeading}>
        {!['pay_with_bank', 'bank_transfer', 'card', 'mobile_money'].includes(stage) ? (
          avatar && (
            <div className="merchant-logo">
              <img src={avatar} alt="merchant-logo" />
            </div>
          )
        ) : (
          <div className="stage-heading">
            <img src={headingImage || switchPaymentChannelImage()[stage as keyof switchPaymentChannelImageType][0]} alt={`${stage} icon`} />
          </div>
        )}

        <h1>
          Pay {currency} {formatAmount(parseFloat(conversion?.payment_amount || amount) + parseFloat(fees?.[stage]?.fee || 0) + parseFloat(fees?.[stage]?.vat || 0))}
        </h1>
        {stage === 'bank_transfer' && (
          <Copyable
            spanClassName={'bt_copy_amount'}
            textModifier={() => 'Tap to copy amount'}
            showIcon={false}
            text={formatAmount(parseFloat(amount) + parseFloat(fees?.[stage]?.fee || 0) + parseFloat(fees?.[stage]?.vat || 0))}
          />
        )}
        {conversion && 
            <div className='currency-exchange-rate'>
              <p className='exchange'>{`Equivalent of ${switchCountryCurrency(conversion?.currency)}${formatAmount(conversion?.amount)}`}</p>
          </div>
        }
      </section>
    </>
  );
}
