import React, { useState } from 'react';
import { modalStore, sendMessage } from '../state';
import {validateEmail, formatAmount, cleanInput, allowOnlyNumeric, environments} from '../utils/formats';
import APIRequest from '../services/api-service';

const api = new APIRequest();

export default function Link() {
  const {
    paymentLink: { name, description, merchant, plan, reference, transaction_limit, checkout_customization },
    public_key,
    update
  } = modalStore();
  const [disabled, setDisabled] = useState(false);
  const [customerDetails, setCustomerDetails] = useState({
    customerName: '',
    customerEmail: '',
    amount: plan?.amount === '0.00' ? '' : plan?.amount,
    currency: plan?.currency
  });

  const handleDisabled = () => {
    if (disabled) return true;
    if (customerDetails.customerEmail === '' || !validateEmail(customerDetails.customerEmail.trim())) return true;
    if (customerDetails.amount === '') return true;
    if (parseFloat(customerDetails.amount) < transaction_limit?.min || parseFloat(customerDetails.amount) > transaction_limit?.max)
      return true;
    return false;
  };

  const payLink = () => {
    setDisabled(true);
    const payload = {
      data: {
        customer: {
          name: customerDetails.customerName,
          email: customerDetails.customerEmail.trim()
        },
        amount: customerDetails.amount,
        currency: customerDetails.currency,
        payment_request: {
          reference: reference
        }
      },
      public_key
    };
    update({
      hideHeading: true
    });
    sendMessage('INIT_CHARGE_PAYMENTLINK', () => api.setUpTransaction('payment-link', payload));
  };
  
  return (
    <div className="kpy-col__checkout-container">
      {checkout_customization?.logo && (
        <div className="merchant-logo" data-testid="merchant-logo">
          <img src={checkout_customization.logo} alt="merchant-logo" />
        </div>
      )}

      <div className="checkout-header" data-testid="checkout-header">
        <h1 className="checkout-name">{name}</h1>
        <p className="merchant-name">{merchant?.name}</p>
        <p className="description">{description}</p>
      </div>

      <div className="kpy-col__container kpy-col-paymentLink-container">
        {process.env.VITE_ENV_TYPE === environments.TEST && (
          <div className="test-mode-indicator">
            <p>TEST MODE</p>
          </div>
        )}
        {parseFloat(plan?.amount || 0) < 1 || (
          <section className="amount-holder" id="amount-holder" data-testid="amount-holder">
            <h1>
              Pay {customerDetails.currency} {formatAmount(plan?.amount || 0)}
            </h1>
          </section>
        )}
        <form onSubmit={e => e.preventDefault()} className="checkout-form-container">
          <div className="kpy-col-input-field">
            <label htmlFor="customer-name" className="kpy-col-label">
              Full Name
            </label>
            <input
              className="kpy-col-input pay-link"
              data-testid="customer-name-input"
              id="customer-name"
              name="customer-name"
              placeholder="John Doe"
              value={customerDetails.customerName}
              onChange={e =>
                setCustomerDetails({
                  ...customerDetails,
                  customerName: cleanInput(e.target.value)
                })
              }
            />
          </div>

          <div className="kpy-col-input-field">
            <label htmlFor="customer-email" className="kpy-col-label">
              Email
            </label>
            <input
              kpy-email-input="true"
              className="kpy-col-input pay-link"
              id="customer-email"
              name="customer-email"
              placeholder="email@example.com"
              value={customerDetails.customerEmail}
              onChange={e =>
                setCustomerDetails({
                  ...customerDetails,
                  customerEmail: cleanInput(e.target.value)
                })
              }
            />
          </div>

          {(!plan.amount || parseFloat(plan.amount) === 0) && (
            <div className="kpy-col-input-field ">
              <label htmlFor="link-amount" className="kpy-col-label">
                Amount ({customerDetails.currency})
              </label>
              <input
                className="kpy-col-input pay-link"
                id="link-amount"
                name="link-amount"
                type="number"
                placeholder={`Minimum ${plan?.currency}${transaction_limit?.min}.00`}
                value={customerDetails.amount}
                onChange={e =>
                  setCustomerDetails({
                    ...customerDetails,
                    amount: allowOnlyNumeric(e.target.value)
                  })
                }
              />
              <p style={{ marginTop: '5px' }} className="kpy-col-label">
                Maximum amount: <strong>{`${customerDetails?.currency} ${transaction_limit?.max.toLocaleString()}.00`}</strong>
              </p>
            </div>
          )}

          <button
            type="submit"
            className="kpy-col-btn"
            style={{ background: checkout_customization?.primary_color, color: checkout_customization?.secondary_color }}
            disabled={handleDisabled()}
            onClick={payLink}
          >
            Proceed
          </button>
        </form>
      </div>
    </div>
  );
}
