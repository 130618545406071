import { useState, useRef, useEffect, useLayoutEffect, ReactNode } from "react";
import { modalStore } from "../../state";

interface HelpProps {
  heading?: string;
  description?: string;
  goBack: () => void;
  helpMessages: ReactNode[];
}

const Help = ({
  heading = "Need help?",
  description = "Having trouble with this payment?",
  goBack,
  helpMessages,
}: HelpProps) => {
  const { update, merchant } = modalStore();
  const [step, setStep] = useState(1);

  const helpMessageWrapper = useRef(null);
  const touchstartX = useRef(0);
  const touchendX = useRef(0);

  useLayoutEffect(() => {
    update({ page: "Help" });
  }, []);

  useEffect(() => {
    const currentHelpMessage = helpMessageWrapper.current;
    if (merchant?.supportEmail && helpMessages.length === 2) {
      helpMessages.push(
        <>
          If you are experiencing any other issue with your payment, please
          contact the Support Team.
          <span className="support-email">{merchant?.supportEmail}</span>
        </>
      );
    }

    function handleSwipe() {
      if (
        touchendX.current < touchstartX.current &&
        step < helpMessages.length
      ) {
        setStep((currentStep) => currentStep + 1);
      }

      if (touchendX.current > touchstartX.current && step > 1) {
        setStep((currentStep) => currentStep - 1);
      }
    }

    function touchstartFn(event: TouchEvent) {
      touchstartX.current = event.changedTouches[0].screenX;
    }

    function touchendFn(event: TouchEvent) {
      touchendX.current = event.changedTouches[0].screenX;
      handleSwipe();
    }
    if (currentHelpMessage){
      (currentHelpMessage as HTMLDivElement).addEventListener(
        "touchstart",
        touchstartFn,
        false
      );
      (currentHelpMessage as HTMLDivElement).addEventListener(
        "touchend",
        touchendFn,
        false
      );
    }
    

    return () => {
       if (currentHelpMessage) {
         (currentHelpMessage as HTMLDivElement).addEventListener(
           "touchstart",
           touchstartFn,
           false
         );
         (currentHelpMessage as HTMLDivElement).addEventListener(
           "touchend",
           touchendFn,
           false
         );
       }
    };
  }, [step, merchant?.supportEmail]);

  function setScrollPercent() {
    return (step - 1) * (1 / helpMessages.length) * 100;
  }

  const helpMessage = () => {
    return (
      <>
        <div className="custom-info">
          <div className="--help-controls">
            <button
              disabled={step === 1}
              className="--left-btn"
              onClick={() => setStep((currentStep) => currentStep - 1)}
              aria-label="Move to previous message"
            >
              <svg
                width="10"
                height="18"
                viewBox="0 0 10 18"
                aria-hidden="true"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                title="left chevron icon"
              >
                <path
                  d="M1.25 16.5L8.75 9L1.25 1.5"
                  stroke="#3E4B5B"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
            <span id="step-counter" data-testid="step-counter">{step}</span>
            <button
              disabled={step === helpMessages.length}
              className="--right-btn"
              data-testid="next-btn"
              onClick={() => setStep((currentStep) => currentStep + 1)}
              aria-label="Move to next message"
            >
              <svg
                width="10"
                height="18"
                viewBox="0 0 10 18"
                aria-hidden="true"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                title="right chevron icon"
              >
                <path
                  d="M1.25 16.5L8.75 9L1.25 1.5"
                  stroke="#3E4B5B"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>

          <div className="--help-messages-w">
            <div
              className="--help-messages"
              ref={helpMessageWrapper}
              style={{
                width: `${helpMessages.length * 100}%`,
                transform: `translateX(-${setScrollPercent()}%)`,
              }}
            >
              {helpMessages.map((message, index) => (
                <p
                  className="content"
                  key={index}
                  aria-hidden={step !== index + 1}
                >
                  {message}
                </p>
              ))}
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="kpy-col__bank-help" data-testid="bank-help">
      <section className="kpy-col__header --help" id="kpy-col__header">
        <h1>{heading}</h1>
        <p>{description}</p>
      </section>

      {helpMessage()}

      {step === 3 && (
        <a
          className="kpy-feedback-btn contact-support"
          href={`mailto:${merchant?.supportEmail}`}
          target="_blank"
          rel="noreferrer"
        >
          Contact Support
        </a>
      )}

      <div className="--help-buttons">
        <button className="--back-btn" onClick={goBack}>
          Go Back
        </button>
      </div>
    </div>
  );
};


export default Help;
