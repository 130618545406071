import { getExpiryYear } from '../utils/formats';

export const issuedVirtualCard = null;

export const successNoAuthCard = {
  number: '4084127883172787',
  expiry_month: '09',
  expiry_year: getExpiryYear(),
  cvv: '123'
};

export const successPINCard = {
  number: '5188513618552975',
  expiry_month: '09',
  expiry_year: getExpiryYear(),
  cvv: '123',
  pin: '1234'
};

export const successOTPCard = {
  number: '5442056106072595',
  expiry_month: '09',
  expiry_year: getExpiryYear(),
  cvv: '123',
  pin: '1234',
  otp: '123456'
};

export const successAVSCard = {
  number: '5384063928932071',
  expiry_month: '09',
  expiry_year: getExpiryYear(),
  cvv: '123',
  pin: '1234',
  avs: {
    city: 'Lekki',
    address: 'Osapa, Lekki',
    state: 'Lagos',
    country: 'Nigeria',
    zip_code: '101010'
  }
};

export const success3DSCard = {
  number: '4562543755474674',
  expiry_month: '09',
  expiry_year: getExpiryYear(),
  cvv: '123',
  pin: '1234'
};

export const successCardEnrollCard = {
  number: '5061460410120223210',
  expiry_month: '09',
  expiry_year: getExpiryYear(),
  cvv: '123',
  pin: '1234',
  otp: '123456',
  phone: '08020000000'
};

export const failedInsufficientFundsCard = {
  number: '506066506066506067',
  expiry_month: '09',
  expiry_year: getExpiryYear(),
  cvv: '408',
  pin: '1234',
  otp: '123456'
};
