import { api } from '../state';
import { threeDSPopupClose, hasAccessToWindow, isInFrame } from '../utils';

export const handleOutgoing = (type: string, data: any) => {
  const { redirect_url, payment_reference } = api.getState();
  threeDSPopupClose();
  const info = JSON.stringify({ result: type, data });
  window.parent?.postMessage(info, '*');

  if (redirect_url && type === 'success')
    return setTimeout(() => {
      window.location.href = `${redirect_url}?reference=${payment_reference}`;
    }, 3000);

  if (redirect_url && type === 'close')
    return setTimeout(() => {
      window.location.href = `${redirect_url}?reference=${payment_reference}`;
    }, 500);
};

export const handleTabRedirect = () => {
  const { redirect_url, payment_reference, auth_model } = api.getState();
  if (auth_model === '3DS' && isInFrame() && hasAccessToWindow(window)) {
    // Handles external redirects for verification page in an iframe
    return setTimeout(() => {
      window.parent.location.href = `${redirect_url}?reference=${payment_reference}`;
    });
  }
  setTimeout(() => {
    window.location.href = `${redirect_url}?reference=${payment_reference}`;
  });
};
